<template>
  <div class="languages-switcher">
    <languages-icons
      v-if="isTriggerRequired"
      :name="selectedLang"
      @click.native="openLanguagesModal"
      class="languages-icon"
    />
    <languages-modal />
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
  import {i18n} from "../../../i18n";
  import LanguagesModal from "@/public/components/common/LanguageSwitcher/LanguagesModal.vue";
  import LanguagesIcons from "@/public/components/common/LanguageSwitcher/LanguagesIcons.vue";
export default {
  name: "LanguageSwitcher",
  components: {
    AtOption,
    AtSelect,
    LanguagesIcons,
    LanguagesModal,
  },
  created() {
    this.selectLang(i18n.locale);
  },
  props: {
    isTriggerRequired: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('user', [
      'lang',
    ]),
    allLangs() {
      return this.langs;
    },
    selectedLang: {
      get() {
        return this.lang;
      },
      async set(value) {
        localStorage.setItem('lang', value);
        await this.selectLang(value);
      },
    },
  },
  methods: {
    ...mapActions( 'app',
        ['openModal'],
    ),
    ...mapMutations ('user', ['changeLangMutations']),
    selectLang(value) {
      // this.changeLanguage(value);\
      this.changeLangMutations(value)
    },
    openLanguagesModal() {
      const { openModal } = this;

      openModal({
        name: 'LanguagesModal',
      });
    }
  },
};
</script>

<style lang="scss">
  .languages-switcher {
    position: absolute;
    .languages-icon {
      cursor: pointer;
    }
  }
</style>
