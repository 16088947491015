<template>
    <div class="organization-role">
        <a class="organization-role__return" @click="goBack">
            <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.07812 0.583127C6.81906 0.454574 6.50723 0.476409 6.27015 0.639703L0.825704 4.3897C0.621307 4.53048 0.5 4.75779 0.5 5C0.5 5.24221 0.621307 5.46952 0.825704 5.6103L6.27015 9.3603C6.50723 9.52359 6.81906 9.54543 7.07812 9.41687C7.33717 9.28832 7.5 9.03094 7.5 8.75V1.25C7.5 0.969062 7.33717 0.71168 7.07812 0.583127Z" fill="#6750A4" stroke="#6750A4" stroke-linejoin="round"/>
            </svg>
            {{ $t('orphan.return') }}
        </a>

        <div class="organization-role__information-wrapper">
            <div class="organization-role__title">
                {{ $t('organization.organization_information_role') }}
            </div>
            <div class="organization-role__input-wrapper">
<!--                <span class="organization-role__input-title">-->
<!--                    {{  }}-->
<!--                </span>-->
                <base-input
                        class="organization-role__input"
                        :label="$t('organization.organization_company_name')"
                        :placeholder="$t('organization.organization_enter_title')"
                        v-model="name"
                        :maxlength="30"
                />
            </div>
            <div class="organization-role__input-wrapper">
<!--                <span class="organization-role__input-title">-->
<!--                     {{  }}-->
<!--                </span>-->
                <base-input
                        class="organization-role__input"
                        :label="$t('organization.organization_comment')"
                        :placeholder="$t('organization.organization_enter_comment')"
                        :maxlength="50"
                        v-model="comment"
                />
            </div>
        </div>
<!--        <div class="organization-role__separator"></div>-->
        <div class="organization-role__checkbox-wrapper">
            <label v-for="(item, index) in sortObj"
                   class="container"
                   :key="index"
            >
                <span class="container__mainTitle">{{ $t(`organization.${item.name}`) }}</span>
                <input class="check-highload"
                       type="checkbox"
                       v-model="item.isAllowed"
                       :name="item.name"
                       :checked="item.isAllowed"
                       @click="toggleEntities(rules, item.name, item.isAllowed)"
                >
                <span class="highload2"></span>
<!--                v-if="item.name === 'webhooks' || item.name === 'collecting'"-->
                <template>
                    <label v-for="([ key, value ], index) of Object.entries(item.entities)"
                           class="container"
                           :key="index"
                    >
                        {{ $t(`organization.${key}`) }}
                        <input class="check-highload"
                               type="checkbox"
                               v-model="value"
                               :name="key"
                               :checked="value"
                               @click="toggleEntities(rules, key, value, item.name)"
                        >
                        <span class="highload2"></span>
                    </label>
                </template>
            </label>
        </div>
        <div class="organization-role__save">
            <base-button
                    type="primary"
                    @click="actionRole"
                    :label="changeRole ? $t('organization.organization_btn_save') : $t('organization.organization_btn_create')"
                    :disabled="disabledBtn"
            />
        </div>
    </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from "vuex";
import _ from "lodash";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
import BaseInput from "@/public/components/common/uiKit/input/base/index.vue";
const mokedCreateRole =  {
    advanced_balance: {
        isAllowed: true,
        entities: {
            view_advanced_balance_history: true
        }
    },
    wallets_payin: {
        isAllowed: true,
        entities: {
            view_wallets: true,
            make_transfer: true,
            make_withdrawal: true
        }
    },
    wallets_business: {
        isAllowed: true,
        entities: {
            view_wallets: true,
            make_withdrawal: true,
            make_transfer: true,
            create_business_address: true,
            manage_business_address: true
        }
    },
    wallets_crosschain_transfer: {
        isAllowed: true,
        entities: {
            make_crosschain_transfer: true,
            view_crosschain_transfers: true
        }
    },
    wallets_crosschain_swap: {
        isAllowed: true,
        entities: {
            make_crosschain_swap: true,
            view_crosschain_swaps: true
        }
    },
    payout_balances: {
        isAllowed: true,
        entities: {
            view_payout_balances: true,
            create_payout_balances: true,
            make_withdrawal: true
        }
    },
    address_book: {
        isAllowed: true,
        entities: {
            view_address_book: true,
            update_address_book: true,
            manage_address_book: true
        }
    },
    transactions: {
        isAllowed: true,
        entities: {
            view_transactions: true
        }
    },
    invoices: {
        isAllowed: true,
        entities: {
            view_invoices: true,
            create_invoice: true
        }
    },
    orders: {
        isAllowed: true,
        entities: {
            view_orders: true,
            create_order: true
        }
    },
    orphan: {
        isAllowed: true,
        entities: {
            view_transactions: true,
            make_withdrawal: true
        }
    },
    recurrent: {
        isAllowed: true,
        entities: {
            create_merchant: true,
            view_billing_links: true,
            create_billing_link: true,
            view_addresses: true,
            make_withdrawal: true,
            make_transfer: true
        }
    },
    personal: {
        isAllowed: true,
        entities: {
            make_withdrawal: true,
            view_users: true,
            manage_users: true,
            view_addresses: true,
            manage_addresses: true
        }
    },
    api_keys: {
        isAllowed: true,
        entities: {
            view_api_keys: true,
            manage_api_keys: true
        }
    },
    'bank_accounts': {
      entities: {
        create:true,
        view:true
      },
      isAllowed: true,
    },
    webhooks: {
        isAllowed: true,
        entities: {
            view_webhooks: true,
            send_webhook_again: true
        }
    },
    collecting: {
        isAllowed: true,
        entities: {
					make_transfer: true,
          make_withdrawal:true,
          manage_settings:true,
          view_addresses:true,
          view_dashboard:true,
          view_history:true,
          view_settings:true,
        }
    },
  iframe_widgets: {
    isAllowed: true,
    entities: {
      create:true,
      delete:true,
      update:true,
      view:true,
    }
  },
   analytics: {
        isAllowed: true,
        entities: {
            view_analytics: true
        }
    }
}
export default {
    name: 'OrganizationListRole',

    components: {BaseInput, BaseButton},

    data() {
        return {
            name: '',
            comment: '',
            rules: null,
            sortObj: null,
        };
    },

    computed: {
        ...mapState('organizations', [
            'changeRole',
            'createRole',
            'selectedRole',
            ]
        ),

      ...mapGetters('app', [
        'getSelectedOrganization'
      ]),

        disabledBtn() {
            return !this.name.length;
        },
    },

    mounted() {
        this.setRoleInfo();
    },
    methods: {
        ...mapActions('organizations', [
            'getOrganizationUsers',
            'getOrganizationRoles',
            'updateOrganizationRole',
            'createOrganizationRole',
        ]),

        goBack() {
            this.getOrganizationRoles();
            this.$store.commit('organizations/changeDetailRole', false);
            this.$store.commit('organizations/setSelectedRole', null);
            this.$store.commit('organizations/setDetailRoleId', null);
            this.$store.commit('organizations/setChangeRoleStatus', false);
            this.rules = JSON.parse(JSON.stringify(mokedCreateRole))
        },

        actionRole() {
            this.$store.commit('organizations/changeDetailRole', false);
            if (this.changeRole) {
                this.updateOrganizationRole({
                    name: this.name,
                    comment: this.comment,
                    rules: this.rules,
                }).then(() => {
                    this.getOrganizationRoles();
                });
            } else {
                this.createOrganizationRole({
                    name: this.name,
                    comment: this.comment,
                    rules: this.rules,
                }).then(() => {
                    this.getOrganizationRoles();
                });
            }
            this.rules = JSON.parse(JSON.stringify(mokedCreateRole))
            this.$store.commit('organizations/setSelectedRole', null);
            this.$store.commit('organizations/setChangeRoleStatus', false);
        },

        setRoleInfo() {
          const excludeTesnet = ["wallets_crosschain_swap", "wallets_crosschain_transfer"]

            if (!this.createRole || this.selectedRole){
                this.name = this.selectedRole ? this.selectedRole.name : '';
                this.comment = this.selectedRole ? this.selectedRole.comment : '';
            }
            this.rules = this.selectedRole ? JSON.parse(this.selectedRole.rules) : JSON.parse(JSON.stringify(mokedCreateRole));
            // сортировка
            this.sortObj = Object.entries(this.rules).map(([key, value]) => ({
                name: key,
                entities: value.entities,
                isAllowed: value.isAllowed,
            }));

            if (this.getSelectedOrganization.isTest) {
              this.sortObj = this.sortObj.filter((item) => !excludeTesnet.includes(item.name));
            }

            this.sortObj = _.sortBy(this.sortObj, ['name']);
        },

        updateRoleInfo () {
            this.sortObj = Object.entries(this.rules).map(([key, value]) => ({
                name: key,
                entities: value.entities,
                isAllowed: value.isAllowed,
            }));
            this.sortObj = _.sortBy(this.sortObj, ['name']);
        },

        toggleEntities(obj, searchKeys, isAllowed, name = '') {

            if (name) {
                // searching and set value of child element
                obj[name].entities[searchKeys] = !isAllowed

                const entities = Object.values(obj[name].entities)

                // If all elements not allowed, parent element not allowed either
                // entities is object

                const isAllElementsInactive = entities.every(item => !item)
                const isElementActive = entities.some(item => item)
                if (isAllElementsInactive) {
                    obj[name].isAllowed = false;
                } else if (isElementActive) {
                    obj[name].isAllowed = true;
                }
                this.updateRoleInfo()
                return
            }
            // if the parent element not allowed child element not allowed either
            obj[searchKeys].isAllowed = !isAllowed;

            for (let entities in obj[searchKeys].entities) {
                obj[searchKeys].entities[entities] = obj[searchKeys].isAllowed;
            }
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .at-btn:disabled {
  background: var(--disabled-color-modal);
  border: 1px solid var(--disabled-color-modal);
  pointer-events: none;
}

.organization-role {
  width: 100%;
  display: flex;
  flex-direction: column;
  //border: 1px solid var(--border-color);
  //background: var(--secondary-color);
  //box-shadow: 0 8px 50px rgba(10, 17, 105, .08);
  //border-radius: 20px;
  align-items: flex-start;
  overflow: hidden;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    border: none;
    box-shadow: none;
    margin-bottom: 0;
  }
  &__return {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--new-front-bg-btn-primary-bg-modal-primary);
    font-size: $h4;
    font-weight: 500;
    margin-bottom: $p20;
  }
  &__btn {
    background: #0059F7;
    border-radius: 6px;
    border: none;
    width: 100%;
    max-width: 125px;
    min-height: 40px;
    transition: all 0.3s ease-in;
    margin: 30px;

    &.save {
      min-width: 300px;
      min-height: 46px;
      margin: 50px 0;
    }

    &:hover {
      background: var(--blue-400);
    }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }

    @media screen and (max-width: 768px) {
      margin: 10px 0 20px 0;

      &.save {
        margin: 50px 0 20px 0;
      }
    }
  }

  &__information-wrapper {
    display: flex;
    flex-direction: column;
    //padding: 0 50px;
    margin-bottom: 50px;
    width: 100%;

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  &__title {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: var(--new-front-primary-font-color);
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 20px;
    }
  }

  &__input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input-title {
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--new-front-primary-font-color);
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 5px;
    }
  }


  &__separator {
    width: 100%;
    max-width: 300px;
    height: 1px;
    background: var(--border-color);
    margin: 50px 0;

    @media screen and (max-width: 768px) {
      margin: 30px 0 20px 0;
    }
  }

  &__checkbox-wrapper {
    width: 100%;
    flex-direction: column;
    //padding: 0 50px;
    max-width: 1430px;
    display: grid;
    grid-template-columns: repeat(3, 33%);

    @media screen and (max-width: 768px) {
      grid-template-columns: auto;
      padding: 0;
      justify-content: space-between;
    }
  }

  &__save {
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: flex-start;
    margin-top: 50px;

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  &__checkbox {
    opacity: 0;
  }

  &__checkbox-label {
    content: '\2713';
    text-align: center;
    color: white;
    line-height: 1em;
    width: 1em;
    height: 1em;
    border: 2px inset #000;
    border-radius: 0.25em;
    margin: 0.25em;
    display: inline-block;
  }
}

/* контейнер */
.container {
  display: flex;
    flex-direction: column;
    gap: 5px;
  max-width: 210px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: $Gilroy;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  height: fit-content;
  color: var(--new-front-primary-font-color);
  &__mainTitle {
    margin-bottom: 10px;
  }
}

/* скрываем дефолтный флажок */
.container .check-highload {
  position: absolute;
  opacity: 0;
}

/* Создаем кастомный checkbox */
.highload2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 2px solid var(--new-front-secondary-font-color);
  transition: all .3s ease;
  border-radius: 3px;
}

/* При наведении указателя мыши перекрашиваем */
.container:hover .check-highload ~ .highload2 {
  border: 2px solid var(--new-front-purple);
}

/* Когда отмечен, то красим в зеленый цвет */
.container .check-highload:checked ~ .highload2 {
  background-color: var(--new-front-purple);
  border: 2px solid var(--new-front-purple);
}

/* Создаем когда отмечено (не видно, когда не отмечено) */
.highload2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Показываем когда отмечен */
.container .check-highload:checked ~ .highload2:after {
  display: block;
}

/* Стили индикатора */
.container .highload2:after {
  left: 50%;
  top: 50%;
  width: 8px;
  height: 12px;
  border: solid var(--new-front-primary-font-color-reverse);;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -66%) rotate(45deg);
  border-radius: 1px;
}
</style>
