import {getAccountBalances} from "./actions/getAccountBalances";
import {getPrice} from "./actions/getPrice";
import {updateAccountBalances} from "./actions/updateAccountBalances";
import {getAccountBalancesGroupedQuery} from "./actions/getAccountBalancesGroupedQuery";
import {getAccountBalancesGroupedQueryPayin} from "./actions/getAccountBalancesGroupedQueryPayin";
import {getAccountBalancesGroupedQueryBusiness} from "./actions/getAccountBalancesGroupedQueryBusiness";
import {getAccountBalancesPayin} from "./actions/getAccountBalancesQueryPayin";
import {getAccountBalancesBusiness} from "./actions/getAccountBalancesQueryBusiness";
import {getAccountBalancesRecurrent} from "./actions/getAccountBalancesQueryRecurrent";
import {getAccountBalancesCollect} from "./actions/getAccountBalancesQueryCollect";
import {addressBalancesSubscription} from "@/public/store/modules/balances/actions/addressBalancesSubscription";
import {markAddressArchived} from "/public/store/modules/balances/actions/markAddressArchived";

export const actions = {
    getAccountBalances,
    getAccountBalancesGrouped: getAccountBalancesGroupedQuery,
    getAccountBalancesGroupedPayin: getAccountBalancesGroupedQueryPayin,
    getAccountBalancesGroupedBusiness: getAccountBalancesGroupedQueryBusiness,
    getAccountBalancesPayin: getAccountBalancesPayin,
    getAccountBalancesBusiness: getAccountBalancesBusiness,
    getAccountBalancesRecurrent: getAccountBalancesRecurrent,
    getAccountBalancesCollect: getAccountBalancesCollect,
    updateAccountBalances,
    getPrice,
    addressBalancesSubscription,
    markAddressArchived
};
