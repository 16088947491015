import gql from 'graphql-tag';

export default gql`
     mutation($organizationId: String!, $widget: IFrameWidgetCreatePayload!) {
         createIFrameWidget(organizationId: $organizationId, widget: $widget) {
            success
            code
            result {
                id
                organizationId
                alias
                theme
                accentColor
                apiKeyId
                link
                siteURL
                showStartPage
                payinCrypto
                payoutCrypto
                payinCryptoType
                payinCryptoInvoiceFree
                payinCryptoInvoiceInsurancePercent
                payinCryptoInvoiceSlippagePercent
                payinCryptoInvoiceTolerancePercent
                payinCryptoInvoiceCommissionOnPayer
                payinCryptoInvoicePayerEmailAllow
                payinCryptoInvoicePayerEmailRequired
                payinCryptoInvoiceCurrencies {
                    currency
                    currencyId
                    currencyAlias
                    isFiat
                    available
                }
                payinCryptoCurrenciesTo {
                        currency
                        currencyId
                        currencyAlias
                        network
                        networkId
                        networkName
                        contract
                        available
                }
                payoutCryptoCurrenciesTo {
                        currency
                        currencyId
                        currencyAlias
                        network
                        networkId
                        networkName
                        contract
                        available
                }
                payoutCryptoCurrenciesFrom {
                    currency
                    currencyId
                    currencyAlias
                    isFiat
                }  
                payinCryptoWebhookURL
                payoutCryptoWebhookURL
                payoutCryptoApprovalWebhookURL
                createdAt
                updatedAt
                customField
            }
         }
    }
`;
