export const STEP_START_PAGE = 'step_start_page';
export const STEP_PAY_SELECT = 'step_pay_select';
export const STEP_CONSTRUCTOR = 'step_constructor';
export const STEP_WIDGET = 'step_widget';

export const PAYIN_CRYPTO_TYPE_INVOICE = 'INVOICE'
export const PAYIN_CRYPTO_TYPE_PERSONAL = 'PERSONAL'

export const MANAGE_EDIT_TYPE = 'edit'
export const MANAGE_CREATE_TYPE = 'create'
export const MANAGE_DEMO_TYPE = 'demo'

export const COLORS = {
    purple: 'linear-gradient(153deg, #744DB2 -33.83%, #8D4CF5 33.16%, #744DB2 96.25%)',
    orange: 'linear-gradient(153deg, #CC4C03 -33.83%, #EF6414 33.16%, #CC4C03 96.25%)',
    green: 'linear-gradient(153deg, #4DA35D -33.83%, #29953D 33.16%, #4DA35D 96.25%)',
    red: 'linear-gradient(153deg, #B3203B -33.83%, #BA0A0A 33.16%, #B3203B 96.25%)',
    yellow: 'linear-gradient(153deg, #CF9500 -33.83%, #E6A809 33.16%, #CF9500 96.25%)',
    blue: 'linear-gradient(153deg, #006CCF -33.83%, #077DE9 33.16%, #006CCF 96.25%)'
}

export const DEFAULT_WIDGET= {
    alias: '',
    theme: 'dark',
    siteURL: '',
    accentColor: 'purple',
    showStartPage: true,
    payinCrypto: false,
    payoutCrypto: false,
    payinCryptoType: '',
    payinCryptoInvoiceFree: false,
    payinCryptoInvoiceInsurancePercent: '',
    payinCryptoInvoiceSlippagePercent: '',
    payinCryptoInvoiceTolerancePercent: '',
    payinCryptoInvoiceCommissionOnPayer: false,
    payinCryptoInvoicePayerEmailAllow: false,
    payinCryptoInvoicePayerEmailRequired: false,
    payinCryptoInvoiceCurrencies: [],
    payinCryptoCurrenciesTo: [],
    payoutCryptoCurrenciesTo: [],
    payoutCryptoCurrenciesFrom: [],
    payinCryptoWebhookURL: '',
    payoutCryptoWebhookURL: '',
    payoutCryptoApprovalWebhookURL: '',
    apiKeyId: '',
    customField: ''
}

export const DEFAULT_DEMO = {
    accentColor: 'purple',
    payinCryptoInvoiceAmount: '',
    payinCryptoInvoiceCurrencies: [],
    payinCryptoInvoiceId: '',
    payinCryptoInvoiceDesc: '',
    payinCryptoInvoicePayerEmail: '',
    payinCryptoInvoicePayerEmailRequired: '',
    payinCryptoInvoicePayerEmailAllow: '',
    payinCryptoInvoiceLifetime: '',
    payinCryptoWebhook: '',
    payoutCryptoCurrenciesTo: [],
    payoutCryptoWebhook: '',
    payoutCryptoWebhookApproval: '',
    payoutCryptoReceiverEmail: '',
    payoutCryptoCurrenciesFrom: '',
}

export default {
    STEP_START_PAGE,
    STEP_PAY_SELECT,
    STEP_CONSTRUCTOR,
    STEP_WIDGET,
    COLORS
}
