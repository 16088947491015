import getOrganizationQuery from "/public/graphQl/queries/getOrganization.js";

export const getOrganization = async ({commit, dispatch, rootState, rootGetters}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const isOwner = rootGetters['app/isOrganizationOwner'];

    if (!isOwner) {
        return;
    }

    let currenciesData;

    try {
        if (!rootState.app.isLoading) {
            commit('app/setLoading', true, {root: true});
        }
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: getOrganizationQuery,
            variables: {
                organizationId
            },
        }, {root: true});
    } catch (error) {
        commit('app/setLoading', false, {root: true});
        return error;
    } finally {
        commit('app/setLoading', false, {root: true});
    }

    if (currenciesData.message) {
        throw currenciesData.message;
    }

    if (currenciesData.errors) {
        throw currenciesData.errors;
    }

    const {
        data: {
            getOrganization
        },
    } = currenciesData;

    commit('setOrganizationName', getOrganization.name);
    commit('setOrganizationImgFile', null);
    commit('setOrganizationImgLink', getOrganization.logoUrl);
    commit('setOrganizationId', getOrganization.id);
    commit('setOrganizationKyc', getOrganization.kycApprove);
    commit('setOrganizationData', getOrganization);

    return true;
};
