import {
    DEFAULT_DEMO,
    DEFAULT_WIDGET,
    MANAGE_CREATE_TYPE,
    STEP_CONSTRUCTOR,
    STEP_START_PAGE
} from "@/public/pages/widgets/constants";

export const mutations = {
    setWidgets(state, payload) {
        state.widgets = payload;
    },
    setHost(state, payload) {
        state.host = payload;
    },
    setWidget(state, payload) {
        state.widget = payload;
    },
    clearManageState(state, payload) {
        state.widget = {...DEFAULT_WIDGET}
        state.currentStep = STEP_START_PAGE
        state.demo = {...DEFAULT_DEMO}
        state.manageType = MANAGE_CREATE_TYPE
    },
    setWidgetsCount(state, payload) {
      state.widgetsCount = payload;
    },
    setApiKey (state, payload) {
      state.widget.apiKeyId = payload
    },
    setAccent (state, payload) {
        state.widget.accentColor = payload
    },
    setLoading(state, payload) {
        state.isLoading = payload;
    },
    setStartPage(state, payload) {
        state.widget.showStartPage = payload;
    },
    setNextStep (state, step) {
        state.currentStep = state.allSteps[state.allSteps.findIndex(item => item === state.currentStep) + 1]
    },
    setCurrentStep (state, step) {
        state.currentStep = step
    },
    setManageType (state, type) {
        state.manageType = type
    },
    setPrevStep (state, step) {
        state.currentStep = state.allSteps[state.allSteps.findIndex(item => item === state.currentStep) - 1]
    },
    setPayinCryptoType (state, type) {
        state.widget.payinCryptoType = type
        state.widget.payinCrypto = Boolean(type)
    },
    setAlias(state, payload) {
        state.widget.alias = payload;
    },
    setPayoutState (state) {
      state.widget.payoutCrypto = !state.widget.payoutCrypto
    },
    setWebhooksTotal(state, payload) {
        state.webhooksTotal = payload;
    },
    setWebhook(state, payload) {
        state.widget.payinCryptoWebhookURL = payload;
    },
    setCustomField(state, payload) {
        state.widget.customField = payload;
    },
    setTheme(state, payload) {
        state.widget.theme = payload;
    },
    setSiteURL(state, payload) {
        state.widget.siteURL = payload;
    },
    setPayinCrypto(state, payload) {
        state.widget.payinCrypto = payload;
    },
    setPayinCryptoInvoiceFree(state, payload) {
        state.widget.payinCryptoInvoiceFree = payload;
    },
    setPayinCryptoInvoiceInsurancePercent(state, payload) {
        state.widget.payinCryptoInvoiceInsurancePercent = payload;
    },
    setPayinCryptoInvoiceSlippagePercent(state, payload) {
        state.widget.payinCryptoInvoiceSlippagePercent = payload;
    },
    setPayinCryptoInvoiceTolerancePercent(state, payload) {
        state.widget.payinCryptoInvoiceTolerancePercent = payload;
    },
    setPayinCryptoInvoiceCommissionOnPayer(state, payload) {
        state.widget.payinCryptoInvoiceCommissionOnPayer = payload;
    },
    setPayinCryptoInvoicePayerEmailAllow(state, payload) {
        state.widget.payinCryptoInvoicePayerEmailAllow = payload;
    },
    setPayinCryptoInvoicePayerEmailRequired(state, payload) {
        state.widget.payinCryptoInvoicePayerEmailRequired = payload;
    },
    setPayinCryptoInvoiceCurrencies(state, payload) {
        state.widget.payinCryptoInvoiceCurrencies = payload;
    },
    setPayinCryptoCurrenciesTo(state, payload) {
        state.widget.payinCryptoCurrenciesTo = payload;
    },
    setPayoutCryptoCurrenciesTo(state, payload) {
        state.widget.payoutCryptoCurrenciesTo = payload;
    },
    setPayoutCryptoCurrenciesFrom(state, payload) {
        state.widget.payoutCryptoCurrenciesFrom = payload;
    },
    setPayinCryptoWebhookURL(state, payload) {
        state.widget.payinCryptoWebhookURL = payload;
    },
    setPayoutCryptoWebhookURL(state, payload) {
        state.widget.payoutCryptoWebhookURL = payload;
    },
    setPayoutCryptoApprovalWebhookURL(state, payload) {
        state.widget.payoutCryptoApprovalWebhookURL = payload;
    },
    setDemoObj(state, payload) {
        state.demo = payload;
    },

    clearAllStates: (state) => {
        state.webhook = {}
        state.webhooksList = []
    },

};
