<template>
  <div :class="['nav-items']">
    <template>
      <div class="nav-header">
<!--        <Icon v-if="productName !== 'Apollopayment'" icon="logo-sm" />-->
<!--        <Icon v-else icon="apollo-logo-sm" />-->
        <div class="nav-header__close" @click="toggleNav">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="12.8033" width="18" height="3" rx="1" transform="rotate(-45 0 12.8033)" fill="var(--new-front-adv-font-color)"/>
            <rect x="2.12158" width="18" height="3" rx="1" transform="rotate(45 2.12158 0)" fill="var(--new-front-adv-font-color)"/>
          </svg>
        </div>
      </div>
      <NavOrganizationSelect
        :key="organizationsChangeCount"
        v-if="mappedOrganizations.length"
        v-model="localSelectedOrganization"
        :options="mappedOrganizations"
        @onChange="handleChangeOrganization"
      />
      <div class="nav-item-wrapper">
        <NavPinned
		        v-if="!isAlbertCrypto"
          :item="pinned"
          @pin="pinItem"
          @changeOrder="setPinnedOrder"
        />
        <component
          class="nav-item"
          v-for="item in filteredNavItems"
          :is="item.type"
          :key="item.title"
          :title="item.title"
          :item="item"
          :pinned="pinnedMenu"
          @pin="pinItem"
        />
      </div>
    </template>
    <div class="nav-logout">
      <base-button
          type="secondary"
          @click="logOut"
      >
        <template #label>
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style="margin-right: 8px;"
          >
            <g clip-path="url(#clip0_20537_59555)">
              <path d="M9.9668 18.3057H2.49168C2.0332 18.3057 1.66113 17.9336 1.66113 17.4751V2.52492C1.66113 2.06645 2.03324 1.69438 2.49168 1.69438H9.9668C10.4261 1.69438 10.7973 1.32313 10.7973 0.863828C10.7973 0.404531 10.4261 0.0332031 9.9668 0.0332031H2.49168C1.11793 0.0332031 0 1.15117 0 2.52492V17.4751C0 18.8488 1.11793 19.9668 2.49168 19.9668H9.9668C10.4261 19.9668 10.7973 19.5955 10.7973 19.1362C10.7973 18.6769 10.4261 18.3057 9.9668 18.3057Z" :fill="theme === 'dark' ? '#FFFFFF' : '#744DB2'"/>
              <path d="M19.7525 9.40855L14.7027 4.42515C14.3771 4.10288 13.8505 4.10706 13.5282 4.43347C13.206 4.75988 13.2093 5.28562 13.5366 5.60788L17.1454 9.16933H7.47508C7.01578 9.16933 6.64453 9.54058 6.64453 9.99988C6.64453 10.4592 7.01578 10.8305 7.47508 10.8305H17.1454L13.5366 14.3919C13.2093 14.7142 13.2068 15.2399 13.5282 15.5663C13.6055 15.6447 13.6976 15.707 13.7991 15.7494C13.9006 15.7919 14.0096 15.8138 14.1196 15.8138C14.3306 15.8138 14.5415 15.7341 14.7027 15.5746L19.7525 10.5912C19.8308 10.5139 19.893 10.4218 19.9355 10.3203C19.978 10.2188 19.9999 10.1099 20 9.99984C20 9.77734 19.9111 9.56554 19.7525 9.40855Z" :fill="theme === 'dark' ? '#FFFFFF' : '#744DB2'"/>
            </g>
            <defs>
              <clipPath id="clip0_20537_59555">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <span class="nav-logout-text">{{ $t('settings-modal.logout') }}</span>
        </template>
      </base-button>
    </div>
    <div class="nav-logo">
      <Logo />
    </div>
  </div>
</template>

<script>
  import NavItem from "../elements/nav/NavItem";
  import NavAccordion from "../elements/nav/NavAccordion";
  import NavOrganizationSelect from "../elements/nav/NavOrganizationSelect";
  import NavSupportButton from "../elements/nav/NavSupportButton";
  import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
  import selectOrganization from "../../graphQl/mutations/selectOrganization";
  import Icon from "../../components/common/uiKit/Icon";
  import {lkLogoLoader} from "@/public/utils/logo-loader";
  import NavPinned from "@/public/components/elements/nav/NavPinned.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/bg-secondary/index.vue";
  import {isAlbertCrypto, isApollo} from "@/config";
  export default {
    name: "NNav",
    components: {
      BaseButton,
        NavPinned, Icon,
        Logo: lkLogoLoader(),
        NavOrganizationSelect,
        NavSupportButton,
        NavAccordion,
        NavItem,
    },
    data() {
      return {
        isMenuOpen: false,
        organizationsChangeCount: 0,
        localSelectedOrganization: '',
	      isAlbertCrypto: isAlbertCrypto,
        navItems: [
              {
                  type: 'NavItem',
                  title: "navigation.main",
                  icon: 'home',
                  roleAlias: 'dashboard',
                  navAlias: 'dashboard',
                  link: {name: 'dashboard'},
                  active: false,
                  summary: true,

              },
              {
                  type: 'NavAccordion',
                  title: "navigation.payments",
                  icon: 'briefcase',
                  roleAlias: 'collecting',
                  navAlias: 'payments-acc',
                  active: false,
                  link: {name: 'payments'},
	                albertCrypto: false,
                  list: [
                      {
                        title: "navigation.widgets",
                        roleAlias: 'iframe_widgets',
                        navAlias: 'widgets',
                        link: {name: 'widgets'},
                        active: false,
                        albertCrypto: false,
                      },
                      {
                          title: 'navigation.addresses_sub',
                          roleAlias: 'wallets_payin',
                          navAlias: 'wallets_payin',
                          link: {name: 'wallets'},
                          active: false,
                          summary: true,
	                        albertCrypto: true,
                      },
                      {
                          title: "navigation.invoice",
                          roleAlias: 'invoices',
                          navAlias: 'invoices',
                          link: {name: 'invoices'},
                          active: false,
	                        albertCrypto: true,
                      },
                      {
                          title: "navigation.orders",
                          roleAlias: 'orders',
                          navAlias: 'orders',
                          link: {name: 'orders'},
                          active: false,
	                        albertCrypto: true,
                      },
                      {
                          title: "navigation.orphan",
                          roleAlias: 'orphan',
                          navAlias: 'orphan',
                          link: {name: 'orphan'},
                          active: false,
	                        albertCrypto: false,
                      },
                      {
                          title: "navigation.personal-addresses",
                          roleAlias: 'personal',
                          navAlias: 'personal',
                          link: {name: 'personal-addresses'},
                          active: false,
                          summary: true,
	                        albertCrypto: true,
                      }
                      // {
                      //   title: "navigation.donations",
                      //   roleAlias: 'donations',
                      //   link: {name: 'donations'},
                      //   active: false
                      // }
                  ]
              },
              {
                type: 'NavItem',
                title: "buyCrypto.title",
                roleAlias: 'crypto',
                navAlias: 'crypto',
                icon: 'commerce',
                link: {name: 'buyCrypto'},
                active: false,
                summary: false,
                albertCrypto: true,
              },
              {
                  type: 'NavItem',
                  title: isAlbertCrypto ? "navigation.wallet" : "navigation.business-wallet",
                  roleAlias: 'wallets_business',
                  navAlias: 'wallets_business',
                  icon: 'wallet',
                  link: {name: 'businessWallet'},
                  active: false,
                  summary: true,
                  albertCrypto: true,
              },
              {
                  type: 'NavItem',
                  title: "navigation.bridge",
                  icon: `left-right`,
                  roleAlias: 'wallets_crosschain_transfer',
                  navAlias: 'wallets_crosschain_transfer',
                  testnetHidden: true,
                  link: { name: 'bridge'},
                  active: false,
                  albertCrypto: true,
              },
              {
                  type: 'NavItem',
                  title: "navigation.swap",
                  icon: `up-down`,
                  roleAlias: 'wallets_crosschain_swap',
                  navAlias: 'wallets_crosschain_swap',
                  testnetHidden: true,
                  link: { name: 'swap'},
                  active: false,
                  albertCrypto: true,
              },
              {
                  type: 'NavAccordion',
                  title: "navigation.subscriptions",
                  icon: 'hub',
                  roleAlias: 'recurrent',
                  navAlias: 'recurrent-acc',
                  active: false,
                  link: null,

                  list: [
                      {
                          title: "navigation.payment-bundle",
                          icon: `loyalty`,
                          roleAlias: 'recurrent',
                          navAlias: 'recurrent-bundle',
                          link: {name: 'subscriptions'},
                          active: false
                      },
                      {
                          title: "navigation.payment-address",
                          icon: `point_of_sale`,
                          roleAlias: 'recurrent',
                          navAlias: 'recurrent-address',
                          link: {name: 'recurrentsAddress'},
                          active: false,
                          summary: true
                      },
                  ],
              },
              {
                  type: 'NavItem',
                  title: "navigation.payout_wallet",
                  icon: `payout`,
                  name: 'payout-wallet',
                  roleAlias: 'payout_balances',
                  navAlias: 'payout_balances',
                  link: {name: 'payout-wallet-balance'},
                  active: false,
                  summary: true,

              },
              {
              type: 'NavAccordion',
              title: "navigation.bank",
              icon: 'bank',
              roleAlias: 'bank',
              navAlias: 'bank',
              active: false,
              apolloHidden: true,

              testnetHidden: true,
              link: {name: ''},
              list: [
                // {
                //   title: "navigation.sepa-swift",
                //   icon: `loyalty`,
                //   roleAlias: 'recurrent',
                //   navAlias: 'recurrent-bundle',
                //   link: {name: 'sepa-swift'},
                //   active: false
                // },
                {
                  title: "navigation.accounts",
                  roleAlias: 'bank',
                  navAlias: 'bank',
                  link: {name: 'bank-invoices'},
                  active: false
                },
              ],
            },
              {
                  type: 'NavItem',
                  title: "navigation.address-book",
                  icon: `file`,
                  name: 'addressBook',
                  roleAlias: 'address_book',
                  navAlias: 'address_book',
                  link: {name: 'addressBook'},
                  active: false,
              },
              {
                  type: 'NavItem',
                  title: "navigation.transactions",
                  icon: `clock`,
                  name: 'transactions',
                  roleAlias: 'transactions',
                  navAlias: 'transactions',
                  link: {name: 'transactions'},
                  active: false,
                  albertCrypto: true,
              },
              {
                  type: 'NavItem',
                  title: "navigation.info",
                  icon: `book`,
                  name: 'withdrawal-fees',
                  roleAlias: 'withdrawal-fees',
                  navAlias: 'withdrawal-fees',
                  link: {name: 'withdrawal-fees'},
                  active: false,

              },
              {
                  type: 'NavItem',
                  title: "navigation.referral",
                  icon: `handshake`,
                  name: 'referral',
                  roleAlias: 'referral',
                  navAlias: 'referral',
                  link: {name: 'referral'},
                  active: false,
                  testnetHidden: true,

              },
              {
                  type: 'NavAccordion',
                  title: "navigation.integrations",
                  icon: `connections`,
                  roleAlias: 'integrations',
                  navAlias: 'integrations',
                  link: {name: 'integrations'},
                  active: false,
                  isNew: false,
                  list: [
                      {
                          type: 'NavItem',
                          title: "navigation.api_keys",
                          icon: `vpn_key`,
                          roleAlias: 'api_keys',
                          navAlias: 'api_keys',
                          link: {name: 'apiKeys'},
                          active: false
                      },
                      // {
                      //     type: 'link',
                      //     title: "navigation.api_docs",
                      //     icon: `description`,
                      //     roleAlias: 'api_docs',
                      //     navAlias: 'api_docs',
                      //     link: this.docsLink(),
                      //     active: false
                      // },
                      // import.meta.env.VITE_API_DOCS_LINK
                      {
                          type: 'NavItem',
                          title: "navigation.webhooks",
                          icon: `description`,
                          roleAlias: 'webhooks',
                          navAlias: 'webhooks',
                          link: { name: 'webhooks' },
                          active: false,
                          isNew: false,
                      },
                      {
                        type: 'NavItem',
                        title: "navigation.integration-settings",
                        icon: `integration-settings`,
                        roleAlias: 'integration-settings',
                        navAlias: 'integration-settings',
                        link: { name: 'integration-settings' },
                        active: false,
                        isNew: false,
                      }
                  ],
              },
          ],
          pinned: {
              title: 'navigation.pinned',
              icon: 'pin',
              list: []
          },
        productName: import.meta.env.VITE_PRODUCT_NAME
      };
    },
    methods: {
      ...mapMutations('app', ['setTheme', 'selectOrganization', 'isInit', 'enableLoading', 'disableLoading', 'setNavState' ]),
      ...mapActions('app', ['request', 'init', 'clearAllStores']),
      ...mapActions('organizations', ['getUserOrganizationRole']),
      ...mapActions('menu', ['sendPinnedMenu', 'getPinnedMenu']),
      ...mapMutations('app', ['toggleNavState']),
      logOut() {
        const logout = window.open('/logout', '_self')
        if (logout) {
          setTimeout(() => {
            window.open('/merchant/login', '_self')
          }, 250)
        }
      },
      toggleNav () {
        this.toggleNavState()
      },
        docsLink () {
            const hostArr = location.host.split('.').reverse()
            if (location.host.includes('localhost')) {
                return 'https://docs.onchainpay.io'
            }
            return `https://docs.${hostArr[1]}.${hostArr[0]}`
        },
        setPinnedOrder (list) {
            this.pinned.list = list
        },
      setActivePage () {
        this.navItems.forEach(item => {
          if (item.list) {
            item.list.forEach(listItem => {
                if (listItem.type === 'link') {
                    return
                }
              listItem.active = this.$route.name === listItem.link.name
            })
            item.active = item.list.some(({ active }) => active)
            return
          }
          item.active = this.$route.name === item.link.name
        })
      },
      async handleChangeOrganization (value) {
        this.clearAllStores();
        this.isInit(false);
        this.selectOrganization(value);
        await this.request({
          type: 'mutation',
          gql: selectOrganization,
          variables: {
            organizationId: value,
          },
        });
        await this.getUserOrganizationRole();
        await this.init();
      },
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
        setPinned () {
          const pinned = this.pinnedMenu
          const list = this.navItems
            pinned.forEach( pin => {
                list.forEach(item => {
                    if (item.list){
                        item.list.forEach((el) => {
                            if (pin === el.navAlias) {
                                this.pinned.list.push(el)
                            }
                        })
                        return
                    }
                    if (pin === item.navAlias) {
                        this.pinned.list.push(item)
                    }
                })

            })
        },
        pinItem (item) {
          if (this.pinned.list.some(({navAlias}) => item.navAlias === navAlias )) {
              this.pinned.list = this.pinned.list.filter(({navAlias}) => item.navAlias !== navAlias )
          } else {
              this.pinned.list.push(item)
          }
          const entities = this.pinned.list.map(({navAlias}) => navAlias)
          this.sendPinnedMenu(entities)
        }
    },
    created() {
      const theme = localStorage.getItem('data-theme-private');
      this.setTheme(theme); //пишем тему в стор

      this.localSelectedOrganization = this.getSelectedOrganizationId;
      this.setActivePage()
        // this.setPinned()
    },
    watch: {
      organizations() {
        this.selectOrganization(this.getSelectedOrganizationId);
        this.localSelectedOrganization = this.getSelectedOrganizationId;
        this.organizationsChangeCount++; //счетчик введен чтобы не терять реактивность на селекте организаций
      },
      $route (){
        this.setActivePage()
        this.setNavState(false)
      },
        pinnedMenu: {
          deep: true,
          immediate: true,
            handler  () {
              this.pinned.list = []
              this.setPinned()
            }
        }
    },
    computed: {
      ...mapState('app', ['theme', 'organizations','selectedOrganizationId', 'isNavOpen']),
      ...mapState('app', { InitState: 'isInit' }),
      ...mapGetters('app', ['getSelectedOrganizationId', 'getSelectedOrganization', 'isOrganizationOwner']),
      ...mapGetters('organizations', ['userRoleRules', 'userAllowedPages']),
      ...mapState('menu', ['pinnedMenu']),
      getNavName() {
        if (!this.getNavAlias) return (this.$route.matched[0].name);
        return this.getNavAlias;
      },
      filteredNavItems () {
          const isSupportActive = import.meta.env.VITE_IS_SUPPORT_ACTIVE === '1'

          if (!isSupportActive) {
              return this.filteredNavigation.filter(({ type }) => type !== 'NavSupportButton')
          }

          return this.filteredNavigation
      },
      filteredNavigation () {
        const isTestnet = this.getSelectedOrganization.isTest;
        let nav = JSON.parse(JSON.stringify(this.navItems))

        const excludeByDefault = ['crypto']

        if (!isAlbertCrypto) {
          nav = nav.filter(({ roleAlias }) => !excludeByDefault.includes(roleAlias))
        }

        if (this.isOrganizationOwner) {
          if (isTestnet) {
            return nav.filter(({testnetHidden}) => !testnetHidden)
          }
          if (isApollo) {
            return nav.filter(({apolloHidden}) => !apolloHidden)
          }
          if (isAlbertCrypto) {
            return nav.filter(({albertCrypto}) => albertCrypto)
          }

            return nav
          }
          const exclude = ['dashboard', 'referral', 'api_docs', 'withdrawal-fees', 'bank'];

          nav.forEach((item) => {
              if (item.list) {
                item.list = item.list.filter((item) => {
                  if (isTestnet && item.testnetHidden) {
                    return false
                  }

                  if (isApollo && item.apolloHidden) {
                    return false
                  }

                  if (isAlbertCrypto) {
                    return item.albertCrypto
                  }

                  if (exclude.includes(item.roleAlias)) { return true }

                  return this.userAllowedPages.some((page) => page === item.roleAlias)
                })
              }
          })
        return nav.filter(({roleAlias, apolloHidden, testnetHidden, type, list, albertCrypto}) => {
          if (isTestnet && testnetHidden) {
            return false
          }
          if (isApollo && apolloHidden) {
            return false
          }

          if (isAlbertCrypto && !albertCrypto) {
            return false
          }

          if (exclude.includes(roleAlias)) { return true }

          if (list) {
            return !!list.length
          }
          return this.userAllowedPages.some((page) => page === roleAlias)
        })
      },
      mappedOrganizations () {
          return this.organizations.map(({id, name, logoUrl}) =>  ({
            value: id,
            title: name,
            logoUrl
          }));
      },
      getNavAlias() {
        return (this.$route?.matched[0].alias[0] ?? '');
      },
      seeMatchedLinks() {
        return this.$route?.matched[0];
      },
      isDarkTheme({ theme }) {
        return theme === 'dark';
      },
    },
  }
</script>

<style lang="scss" scoped>
.nav {
  &-items {

  }
  &-item {
    border-bottom: 1px solid var(--new-front-nav-separator);
    &-wrapper {
    }
  }
  &-logo {
    display: flex;
    align-items: unset;
    justify-content: center;
    margin: $p30 0;
  }
  .nav-hidden {
    display: block;
  }
}
.nav-header {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 $p10;
  margin-top: $p10;
  &__close {
    background: var(--new-front-adv-bg);
    border: 1px var(--new-front-adv-border-color) solid;
    border-radius: 10px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.nav-logout {
  display: none;
  width: 100%;
  margin: 0 20px;
  &-text {
    font-family: $Gilroy;
    font-size: $h4;
    font-weight: 500;
    color: var(--new-front-btn-bg-secondary-text);
  }
}
@media (max-width: 1180px) {
  .nav-hidden {
    display: none !important;
  }
  .nav-logout {
    display: block;
    padding: 20px 20px 0;
    margin: 0;
    width: 100%;
  }
  .nav-header {
    display: flex;
    justify-content: end;
  }
  .nav-logo {
    margin: 20px 0;
  }
  //.nav-logo {
  //  display: none;
  //}
}
</style>
