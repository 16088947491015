export const fr = {
    'invoice': {
        'invoice': 'Compte № :',
        'sum-invoice': 'Le montant de la facture :',
        'select-invoice': 'Sélectionnez la devise du paiement',
        'coin-network': 'Monnaie et réseau :',
        'rate': 'Cours :',
        'sum-payment': 'Montant du paiement :',
        'button': 'Paiement',
        'search': 'Rechercher',
    },
    'checkout': {
        'shop': 'Boutique',
        'payment': 'Paiement',
        'back_to_the_store': 'Retour à la boutique',
        'to_make_a_payment': 'Pour effectuer un paiement, envoyez «{orderCurrency}» au réseau «{orderAlias}». à l`adresse indiquée',
        'payment_completed': 'Paiement exécuté',
        'the_payment_is_invalid': 'Le paiement n`est pas valide',
        'payment_rejected': 'Paiement rejeté',
        'payment_pending': 'Paiement en cours de traitement',
        'payment_init': 'Paiement en cours',
        'lifetime_has_expired': 'La durée de vie du paiement a expiré. N`envoyez pas de pièces à l`adresse indiquée.',
        'contact_the_store_owner': 'La durée de vie du paiement a expiré. N`envoyez pas de pièces à l`adresse indiquée. Contactez le commerçant pour obtenir un remboursement ou pour continuer à payer.',
        'order': 'Commandez',
        'to_be_paid': 'A payer',
        'received': 'Reçu :',
        'description': 'Description :',
        'before_the_expiration_of_the_order': 'Date limite de paiement :',
        'network': 'Réseau :',
        'payment_address': 'Adresse de paiement :',
        'payment_address_not_found': 'Payment address not found ',
        'tag':'Tag :',
        'contract_address': 'Adresse du contrat :',
        'send_to_this_address_only': 'N`envoyez qu`à cette adresse',
        'make_sure_the_network_is_correct': 'Assurez-vous que le réseau est correct :',
        'network_is_correct': 'Assurez-vous que le réseau est correct :',
        'contract_address_matches': 'Assurez-vous que l`adresse du contrat correspond',
        'payment_page': 'Page de paiement',
        'the_address_was_copied': 'Adresse copiée avec succès',
        'the_tag_was_copied': 'L`étiquette a été copiée',
        'invoice_was_successfully_paid': 'Le mandat a été payé avec succès',
        'the_invoice_was_paid': 'Le mandat a été payé pour un montant de',
        'important_info': 'Informations importantes',
        'the_contract_address_does_not_exist_on_this_network': 'L`adresse du contrat n`existe pas sur ce réseau',
        'error': 'Erreur',
        'time_hours': 'heures',
        'time_minute': 'minutes',
        'time_second': 'secondes',
        'sellers_address': 'L`adresse du vendeur est vérifiée',
        'stored_table': 'Voici l`historique des transactions entrantes',
        'history_table': 'Historique des transactions entrantes',
        'full_info': 'Voir tous les détails',
        'table_date': 'Date',
        'table_sum': 'Montant',
        'table_value': 'Monnaie',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Montant et date',
        'payment_partially_paid': 'Partly paid',
        'paid_timer': 'Paid',
        'expired_timer': 'Le paiement a expiré',
    },
    'new_checkout': {
        'details': {
            'title': 'Détails',
            'order': 'Commande',
            'date': 'Date',
            'description': 'Description',
        },
        'notification': "Veuillez envoyer uniquement des jetons <b>{orderCurrency}</b> sur le réseau <b>{orderNetwork}</b> sur cette adresse.",
        'payment_address': 'Adresse de facturation',
        'verified_notification': "L'adresse du vendeur a été vérifiée",
        'paid_amount': "Montant payé",
        'timer_notifications': {
            'remaining_time': "Temps restant pour payer:",
            'completed': "Le paiement a été achevé avec succès",
            'expired_with_invoice': "La durée de vie de la commande a expiré, mais vous pouvez revenir en arrière et créer une nouvelle commande.",
            'expired_without_invoice': 'La durée de vie du paiement a expiré',
            'dont_make_transactions': "N'effectuez aucune transaction sur cette commande!"
        },
        'statuses': {
            'not_paid': 'Impayé',
            'overpaid': 'Trop payant',
            'partially_paid': 'Partiellement payé',
            'fully_paid': 'Entièrement payé',
        },
        'history': {
            'title': 'Historique des paiements',
            'columns': {
                'date': 'Date',
                'amount': 'Montant',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Toutes vos transactions envoyées à l\'adresse spécifiée seront disponibles dans cette fenêtre.',
            'ok': "D'ACCORD"
        },
        'orphan': {
            'title': 'Transaction orpheline',
            'description': 'L\'une des transactions de cette commande contient le mauvais réseau ou la mauvaise devise.',
            'contact': 'Contactez le support technique avec l\'ID',
        },
        'time-is-over': 'Temps est révolu',
        'payment-successful': 'Paiement réussi',
        'partial-desc': 'Le montant a été partiellement payé. le montant restant doit être payé pour un paiement réussi.',
        'cancel': 'Annuler',
        'back_to_shop': 'Retour à la boutique',
        'back_to_invoice': 'Retour à la facture',
        'choose_another_currency': 'Choisissez une autre devise',
        'you_can_pay': 'Vous pourrez payer la facture dans plusieurs devises !'
    },
    'new_invoice': {
        'details': {
            title: 'Détails',
            invoice: 'Facture',
            date: 'Date',
            description: 'Description'
        },
        'amount': 'Montant',
        'btn_label': 'Continuer',
        'btn_label_empty': 'Sélectionnez la devise',
        'cancel': 'Annuler',
        'search': 'Recherche...',
        'back_to_shop': 'Retour à la boutique',
        'choose-currency-and-network': 'Choisissez la devise et le réseau',
        'status': 'Statut',
        'you-payed': 'Vous avez payé',
        'statuses': {
            "ERROR": 'ERREUR',
            "PENDING": 'EN ATTENTE',
            "PROCESSED": 'TRAITÉ',
            "REJECTED": 'REJETÉ',
            'OVERPAID': 'SURPAYÉ',
            "CREATED": 'CRÉÉ',
            'PARTIAL': 'PAYÉ PARTIEL',
            'INIT': 'INIT',
            'EXPIRED': 'EXPIRÉ'
        },
        'active_order': 'Commande active',
        'payment-successful': 'Paiement réussi'
    },
    'languages': {
        'select': 'Choisir la langue'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Page non trouvée',
            'page_not_found_text': 'Nous ne pouvons pas sembler trouver la page que vous recherchez',
        }
    }
};
