import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $limit: Float!, $offset: Float!, $sortDate: String, $type: [String], $currencyId: [String], $networks: [String], $date: [String], $tx: String, $anyId: String) {
        getTransactions(organizationId: $organizationId, limit: $limit, offset: $offset, sortDate: $sortDate, type: $type, currencyId: $currencyId, networks: $networks, date: $date, tx: $tx, anyId: $anyId) {
            transactions {
                id
                #                type
                networkId
                #                createdAt
                #                currencyFrom
                #                currencyTo
                status
                orderId
                createdAt
                #                amountFrom
                #                amountTo
                currencyId
                deposit
                basis
                organizationId
                side
                externalId
                source
                sourceId
                addressTo
                addressFrom
                txId
                deposit
                credit
                type
            }
            totalCount
        }
    }
`;
