export const pl = {
    'invoice': {
        'invoice': '№ konta:',
        'sum-invoice': 'Kwota konta:',
        'select-invoice': 'Wybierz walutę płatności',
        'coin-network': 'Waluta i sieć:',
        'rate': 'Dobrze:',
        'sum-payment': 'Wielkość płatności:',
        'button': 'Zapłata',
        'search': 'Szukaj',
    },
    'checkout': {
        'shop': 'Wynik',
        'payment': 'Zapłata',
        'back_to_the_store': 'Powrót do sklepu',
        'to_make_a_payment': 'Wyślij «{orderCurrency}» w sieci «{orderAlias}» , aby dokonać płatności pod wskazany adres',
        'payment_completed': 'Płatność zakończona',
        'the_payment_is_invalid': 'Płatność jest nieważna',
        'payment_rejected': 'Płatność odrzucona',
        'payment_pending': 'Płatność w przetwarzaniu',
        'payment_init': 'Oczekiwanie na płatność',
        'lifetime_has_expired': 'Okres ważności płatności wygasł. Nie wysyłaj monet pod wskazany adres.',
        'contact_the_store_owner': 'Okres ważności płatności wygasł. Nie wysyłaj monet pod wskazany adres. Skontaktuj się z właścicielem sklepu, aby zwrócić monety lub kontynuować płatność',
        'order': 'Zamówienie',
        'to_be_paid': 'Płacić',
        'received': 'Otrzymane:',
        'description': 'Opis:',
        'before_the_expiration_of_the_order': 'Okres ważności płatności:',
        'network': 'Internet:',
        'payment_address': 'Adres do płatności:',
        'payment_address_not_found': 'Nie znaleziono adresu płatności”,',
        'tag': 'Tag:',
        'contract_address': 'Adres umowy:',
        'send_to_this_address_only': 'Wysyłaj tylko na ten adres',
        'make_sure_the_network_is_correct': 'Sprawdź, czy sieć jest poprawna:',
        'network_is_correct': 'Sprawdź, czy sieć jest poprawna:',
        'contract_address_matches': 'Upewnij się, że adres umowy jest zgodnyt',
        'payment_page': 'Strona płatności',
        'the_address_was_copied': 'Adres skopiowany pomyślnie',
        'the_tag_was_copied': 'Tag został skopiowany',
        'invoice_was_successfully_paid': 'Zamówienie zostało pomyślnie opłacone',
        'the_invoice_was_paid': 'Zamówienie zostało opłacone',
        'important_info': 'Ważna informacja',
        'the_contract_address_does_not_exist_on_this_network': 'Adres umowy nie istnieje w tej sieci',
        'error': 'Błąd',
        'time_hours': 'godziny',
        'time_minute': 'minuty',
        'time_second': 'sekundy',
        'sellers_address': 'Adres sprzedawcy został zweryfikowany',
        'stored_table': 'Tutaj będzie historia transakcji przychodzących',
        'history_table': 'Historie eingehender Transaktionen',
        'full_info': 'Pokaż pełne szczegóły',
        'table_date': 'Data',
        'table_sum': 'Suma',
        'table_value': 'Waluta',
        'table_hash': 'tx_hash',
        'table_date_sum': 'Kwota i data',
        'payment_partially_paid': 'Teilweise bezahlt',
        'paid_timer': 'Zapłacone',
        'expired_timer': 'Płatność wygasła',
    },
    'new_checkout': {
        'details': {
            'title': 'Detale',
            'order': 'Zamówienie',
            'date': 'Data',
            'description': 'Opis',
        },
        'notification': 'Wysyłaj tylko tokeny <b>{orderCurrency}</b> w sieci <b>{orderNetwork}</b> na ten adres.',
        'payment_address': 'Adres do płatności',
        'verified_notification': "Adres sprzedawcy został zweryfikowany",
        'paid_amount': "Zapłacona kwota",
        'timer_notifications': {
            'remaining_time': 'Pozostały czas na zapłatę',
            'completed': 'Płatność została pomyślnie zakończona',
            'expired_with_invoice': 'Okres ważności zamówienia wygasł, ale możesz wrócić i utworzyć nowe zamówienie.',
            'expired_without_invoice': 'Żywotność płatności wygasła',
            'dont_make_transactions': "Nie dokonuj transakcji w ramach tego zamówienia!"
        },
        'statuses': {
            'not_paid': 'Niezapłacone',
            'overpaid': 'Przepłacone',
            'partially_paid': 'Częściowo wypłacone',
            'fully_paid': 'W pełni opłacony',
        },
        'history': {
            'title': 'Historia płatności',
            'columns': {
                'date': 'Data',
                'amount': 'Kwota',
                'tx_hash': 'Tx_hash'
            },
            'empty_notification': 'Wszystkie Twoje transakcje wysłane na określony adres będą dostępne w tym oknie.',
            'ok': 'OK'
        },
        'orphan': {
            'title': 'Transakcja sieroca',
            'description': 'Jedna z transakcji w tym zamówieniu zawiera niewłaściwą sieć lub walutę.',
            'contact': 'Skontaktuj się z pomocą techniczną podając identyfikator',
        },
        'time-is-over': 'Czas się skończył',
        'payment-successful': 'Płatność pomyślna',
        'partial-desc': 'Kwota została częściowo zapłacona. pozostała kwota musi zostać zapłacona, aby płatność przebiegła pomyślnie.',
        'cancel': 'Anulować',
        'back_to_shop': 'Wróć do sklepu',
        'back_to_invoice': 'Powrót do faktury',
        'choose_another_currency': 'Wybierz inną walutę',
        'you_can_pay': 'Fakturę będziesz mógł opłacić w wielu walutach!'
    },
    'new_invoice': {
        'details': {
            title: 'Detale',
            invoice: 'Faktura',
            date: 'Data',
            description: 'Opis'
        },
        'amount': 'Kwota',
        'btn_label': 'Kontynuować',
        'btn_label_empty': 'Wybierz walutę',
        'cancel': 'Anulować',
        'search': 'Szukaj...',
        'back_to_shop': 'Wróć do sklepu',
        'choose-currency-and-network': 'Wybierz walutę i sieć',
        'status': 'Status',
        'you-payed': 'You payed',
        'statuses': {
            "ERROR": 'BŁĄD',
            'PENDING': 'AŻ DO',
            'PROCESSED': 'OBROBIONY',
            'REJECTED': 'ODRZUCONY',
            'OVERPAID': 'PRZEPŁATA',
            'CREATED': 'STWORZONY',
            'PARTIAL': 'CZĘŚCIOWO PŁATNE',
            'INIT': 'WSTĘP',
            'EXPIRED': 'WYGASŁY'
        },
        'active_order': 'Aktywne zamówienie',
        'payment-successful': 'Płatność pomyślna'
    },
    'languages': {
        'select': 'Wybierz język'
    },
    'preCheckout': {
        'errors': {
            'page_not_found': 'Strona nie znaleziona',
            'page_not_found_text': 'Nie możemy znaleźć strony, której szukasz',
        }
    }
};
